import { db } from '../firebase/firebase-config';
import { DocumentReference, CollectionReference, collection, onSnapshot, orderBy, getDocs, deleteDoc, collectionGroup, getCountFromServer, writeBatch, updateDoc, serverTimestamp, runTransaction, where, startAfter, increment, getDoc, doc, setDoc, query, limit, addDoc } from 'firebase/firestore';

import { LeaderboardMemberType } from '../types/LeaderboardMemberType';

import { GameModeType } from '../types/GameModeType';
import { Quiz } from '../types/Quiz';
import { Notification } from '../types/NotificationType';
import { FriendType } from '../types/FriendType';

import { modeKeyMap } from '../helpers/quizHelpers';

import { quizOptionsObject } from '../config/quizOptionsObject';

const USERS_DATABASE = collection(db, 'users')
const QUIZZES_DATABASE = collection(db, 'allQuizzes')
const FRIENDSHIPS_DATABASE = collection(db, 'friendships')
const NOTIFS_DATABASE = collection(db, 'notifications')

const REGION_STATS_DATABASE = collection(db, "regionStats")
const COUNTRY_STATS_DATABASE = collection(db, "countryStats")

const QUIZ_COUNT = 10;
const LEADERBOARD_FETCH_LIMIT = 10;

// QUIZZES

export const fetchQuizzesFirestore = async (gameMode: GameModeType): Promise<Quiz[]> => {
  const formattedGameMode = gameMode.replace(/\s+/g, '');
  let formattedRegion: string | undefined;
  let formattedSuperRegion: string | undefined;

  try {
    if (quizOptionsObject[formattedGameMode]) {
      formattedRegion = formattedGameMode;
    } else {
      formattedSuperRegion = formattedGameMode;
    }

    const quizCollectionRef = QUIZZES_DATABASE;
    let quizzes: Quiz[] = [];
    let fetchedCount = 0;

    while (fetchedCount < QUIZ_COUNT) {
      const randomStartPoint = Math.random();
      let q;

      if (formattedRegion) {
        q = query(
          quizCollectionRef,
          where('region', '==', formattedRegion),
          orderBy('random'),
          startAfter(randomStartPoint),
          limit(QUIZ_COUNT - fetchedCount)
        );
      } else if (formattedSuperRegion) {
        if (formattedSuperRegion === 'Globe') {
          q = query(
            quizCollectionRef,
            orderBy('random'),
            startAfter(randomStartPoint),
            limit(QUIZ_COUNT - fetchedCount)
          );
        } else {
          q = query(
            quizCollectionRef,
            where('superRegion', '==', formattedSuperRegion),
            orderBy('random'),
            startAfter(randomStartPoint),
            limit(QUIZ_COUNT - fetchedCount)
          );
        }
      } else {
        console.warn("Unable to query quizzes, no valid region or superRegion passed")
        return []
      }

      const snapshot = await getDocs(q);

      const batchQuizzes: Quiz[] = snapshot.docs.map(doc => ({
        id: doc.id,
        imageUrl: doc.data().imageUrl,
        country: doc.data().country,
        options: doc.data().options,
        region: doc.data().region,
        superRegion: doc.data().superRegion,
        random: doc.data().random,
        difficulty: doc.data().difficulty,
      }));

      quizzes = [...quizzes, ...batchQuizzes];
      fetchedCount += batchQuizzes.length;

      if (batchQuizzes.length === 0) {
        break;
      }
    }

    // Shuffle the quizzes before returning
    quizzes.sort(() => Math.random() - 0.5);

    // Limit the quizzes to QUIZ_COUNT
    return quizzes.slice(0, QUIZ_COUNT);
  } catch (error) {
    console.error(`Failed to fetch quizzes:`, error);
    return [];
  }
};

export const deleteQuizFirestoreDocument = async (quizId: string, country: string) => {
  const formattedCountry = country.replace(/\s+/g, '');

  try {
    const quizDocRef = doc(QUIZZES_DATABASE, quizId);
    const countryStatsDocRef = doc(COUNTRY_STATS_DATABASE, formattedCountry);
    const totalQuizzesCountDocRef = doc(COUNTRY_STATS_DATABASE, 'totalQuizzesCount');

    await runTransaction(db, async (transaction) => {
      const countryStatsDoc = await transaction.get(countryStatsDocRef);
      const quizDoc = await transaction.get(quizDocRef);

      if (!quizDoc.exists()) {
        alert('Quiz already deleted');
        throw new Error("Quiz document does not exist!");
      }

      // Decrement the quiz count for the country
      if (!countryStatsDoc.exists()) {
        transaction.set(countryStatsDocRef, { quizCount: -1 });
      } else {
        transaction.update(countryStatsDocRef, {
          quizCount: increment(-1),
        });
      }
      // Decrement the total quizzes count
      transaction.update(totalQuizzesCountDocRef, {
        quizCount: increment(-1),
      });

      // Delete the quiz document
      transaction.delete(quizDocRef);
    });

    console.log(`Quiz document with ID ${quizId} deleted successfully and stats updated`);
  } catch (error) {
    console.error("Error deleting quiz document and updating stats:", error);
    throw error;
  }
};


// LEADERBOARDS

export const fetchTopUsersByGamemode = async (gameMode: GameModeType, order: string = "points"): Promise<LeaderboardMemberType[]> => {
  try {
    const formattedGameMode = modeKeyMap[gameMode]
    const gamemodeStatsCollectionGroup = collectionGroup(db, 'gamemodeStats');

    const q = query(
      gamemodeStatsCollectionGroup,
      where('gamemode', '==', formattedGameMode),
      orderBy(order, 'desc'),
      limit(LEADERBOARD_FETCH_LIMIT)
    );

    const querySnapshot = await getDocs(q);

    const result: LeaderboardMemberType[] = [];

    for (const docSnapshot of querySnapshot.docs) {
      const data = docSnapshot.data();
      const currentPoints = data.points;
      const highscoreStreak = data.highscoreStreak;
      const id = data.userId;

      // Fetch the user's display name or username
      const userDocRef = doc(USERS_DATABASE, id);
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      const username = userData?.username || id;

      result.push({
        id,
        username,
        currentPoints,
        highscoreStreak,
      });
    }

    return result;
  } catch (error) {
    console.error(`Error fetching top users by gameMode ${order}:`, error);
    return [];
  }
};


// USER AUTHENTICATION

export const fetchOrCreateUser = async (userId: string, email: string, displayName: string | null) => {
  const userDocRef = doc(USERS_DATABASE, userId);
  const userDoc = await getDoc(userDocRef);

  if (!userDoc.exists()) {
    const newUsername = displayName || `User${Math.floor(Math.random() * 90000 + 10000)}`;
    const newUserType = (email && email.length > 3) ? "authenticated" : "anonymous";
    const subscription = 'Standard'
    const role = 'member';

    await setDoc(userDocRef, {
      userId: userDocRef.id,
      username: newUsername,
      email,
      subscription,
      role,
      createdDate: new Date(),
      loginCount: 0,
      userType: newUserType,
    });
    return { username: newUsername, userType: newUserType, subscription };
  } else {
    return {
      username: userDoc.data().username,
      userType: userDoc.data().userType || "anonymous",
      subscription: userDoc.data().subscription || 'Standard',
      email: userDoc.data().email || "",
      role: userDoc.data().role || "member",
    };
  }
};

export const updateUserLoginStats = async (userId: string) => {
  const userDocRef = doc(USERS_DATABASE, userId);

  try {
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      await updateDoc(userDocRef, {
        lastLogin: new Date(),
        loginCount: increment(1)
      });
    } else {
      console.log("No user document found for userId:", userId);
    }
  } catch (error) {
    console.log(error)
  }
};

export const fetchUserStatistics = async (userId: string, gameMode: GameModeType) => {
  const formattedGameMode = modeKeyMap[gameMode]
  const userDocRef = doc(USERS_DATABASE, userId, "gamemodeStats", formattedGameMode);

  try {
    const userStatsDoc = await getDoc(userDocRef);
    if (userStatsDoc.exists()) {
      const data = userStatsDoc.data();
      return {
        currentPoints: data.points || 0,
        highscoreStreak: data.highscoreStreak || 0
      };
    } else {
      console.log("No user document found for userId: ", userId);
      return { currentPoints: 0, highscoreStreak: 0 };
    }
  } catch (error) {
    console.error("Error fetching user statistics: ", error);
    throw error;
  }
}


// USER STATE MANAGEMENT

export const updateUserTypeToAuthenticated = async (userId: string): Promise<void> => {
  try {
    const userDocRef = doc(USERS_DATABASE, userId);
    await updateDoc(userDocRef, {
      userType: "authenticated",
    });
  } catch (error) {
    console.error(`Error updating userType for userId ${userId}:`, error);
    throw error
  }
};

export const updateUserEmail = async (userId: string, email: string): Promise<void> => {
  try {
    const userDocRef = doc(USERS_DATABASE, userId);
    await updateDoc(userDocRef, {
      email: email,
    });
  } catch (error) {
    console.error(`Error updating userType for userId ${userId}:`, error);
    throw error
  }
}

export const deleteUserData = async (userId: string): Promise<void> => {
  try {
    const userDocRef = doc(USERS_DATABASE, userId);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      console.warn(`Cannot delete user data: User document with userId ${userId} does not exist.`);
      return;
    }

    await deleteUserSubcollections(userDocRef);
    await deleteDoc(userDocRef);
    console.log(`Successfully deleted user data for userId ${userId}.`);
  } catch (error) {
    console.error(`Error deleting user data for userId ${userId}:`, error);
    throw error;
  }
};

const deleteUserSubcollections = async (userDocRef: DocumentReference) => {
  const subcollections = ['gamemodeStats', 'readGlobalNotifications', "notifications"];

  for (const subcollectionName of subcollections) {
    const subcollectionRef = collection(userDocRef, subcollectionName);
    await deleteCollection(subcollectionRef, 10);
  }
};

const deleteCollection = async (collectionRef: CollectionReference, batchSize: number) => {
  const q = query(collectionRef, limit(batchSize));

  return new Promise<void>(async (resolve, reject) => {
    try {
      const snapshot = await getDocs(q);
      const batch = writeBatch(db);

      snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();

      if (snapshot.size === batchSize) {
        await deleteCollection(collectionRef, batchSize);
      }

      resolve();
    } catch (error) {
      console.error('Error deleting collection:', error);
      reject(error);
    }
  });
};

export const updateFirestoreUsername = async (userId: string, newUsername: string, showAlert: (message: string, status: 'red' | 'green' | 'blue') => void) => {
  try {
    const usersRef = USERS_DATABASE;
    const q = query(usersRef, where('username', '==', newUsername));

    const querySnapshot = await getDocs(q);
    const usernameTaken = querySnapshot.docs.some((doc) => doc.id !== userId);

    if (usernameTaken) {
      showAlert('Username already taken, please choose a different username', 'red');
      return null;
    }

    // Update the user's username
    const userDocRef = doc(USERS_DATABASE, userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      await updateDoc(userDocRef, { username: newUsername });
      showAlert(`Updated username to ${newUsername}`, 'green');
      return true;
    } else {
      showAlert('No user document found for userId', 'red');
      return null;
    }
  } catch (error) {
    console.error('Error updating username:', error);
    showAlert('Error updating username, please try again later.', 'red');
    return null;
  }
};


// USER STATS

export const submitReportFirebase = async (
    userId: string, 
    username:string, 
    email:string,
    quizContinent: string,
    quizCountry: string,
    quizId: string,
    quizUrl: string,
    message:string
) => {
  try {
    const reportCollectionRef = collection(db, 'reports');
    const docRef = await addDoc(reportCollectionRef, {
      userId,
      username,
      email,
      message,
      quizContinent,
      quizCountry,
      quizId,
      quizUrl,
      checked: false,
      deleted: false,
      timestamp: new Date()
    });

    await updateDoc(docRef, { reportId: docRef.id });
  } catch (error) {
    console.error("Error submitting report:", error);
    throw error;
  }
};

export const updateGamemodePoints = async (userId: string, gameMode: GameModeType, points: number) => {
  const formattedGameMode = modeKeyMap[gameMode]

  const statsRef = doc(USERS_DATABASE, userId, 'gamemodeStats', formattedGameMode);

  try {
    await setDoc(statsRef, { points }, { merge: true });
  } catch (error) {
    console.error("Error updating gameMode points:", error);
  }
};

export const updateGamemodeHighStreak = async (userId: string, gameMode: GameModeType, highscoreStreak: number) => {
  const formattedGameMode = modeKeyMap[gameMode]

  const statsRef = doc(USERS_DATABASE, userId, 'gamemodeStats', formattedGameMode);

  try {
    await setDoc(statsRef, { highscoreStreak }, { merge: true });
  } catch (error) {
    console.error("Error updating streak points:", error);
  }
};

// GAME STATS

export const increaseGamemodePlayCountFirestore = async (gameMode: GameModeType) => {
  try {
    const formattedGameMode = modeKeyMap[gameMode]
    const gamemodeRef = doc(REGION_STATS_DATABASE, formattedGameMode);
    const gamemodeDoc = await getDoc(gamemodeRef);

    if (gamemodeDoc.exists()) {
      await updateDoc(gamemodeRef, {
        playCount: increment(1),
      });
    } else {
      await setDoc(gamemodeRef, { playCount: 1 });
    }
  } catch (error) {
    console.error('Error increasing play count due to:', error);
  }
};

export const increaseCorrectGuess = async (quizId: string) => {

  const quizDocRef = doc(QUIZZES_DATABASE, quizId);

  try {
    const quizDocSnapshot = await getDoc(quizDocRef);
    if (quizDocSnapshot.exists()) {
      await updateDoc(quizDocRef, {
        correctGuesses: increment(1)
      });
    } else {
      console.error(`Quiz document with ID ${quizId} does not exist.`);
    }
  } catch (error) {
    console.error('Error increasing correct guess count:', error);
  }
};

export const increaseWrongGuess = async (quizId: string) => {
  const quizDocRef = doc(QUIZZES_DATABASE, quizId);

  try {
    const quizDocSnapshot = await getDoc(quizDocRef);
    if (quizDocSnapshot.exists()) {
      await updateDoc(quizDocRef, {
        wrongGuesses: increment(1)
      });
    } else {
      console.error(`Quiz document with ID ${quizId} does not exist.`);
    }
  } catch (error) {
    console.error('Error increasing wrong guess count:', error);
  }
};

export const getUserCount = async () => {
  const usersCollectionRef = USERS_DATABASE;
  const snapshot = await getCountFromServer(usersCollectionRef);
  const userCount = snapshot.data().count;
  return userCount;
}

export const fetchGamemodeStats = async (userId: string, gameMode: GameModeType) => {
  const formattedGameMode = modeKeyMap[gameMode]

  try {
    const statsRef = doc(USERS_DATABASE, userId, 'gamemodeStats', formattedGameMode);
    const statsSnap = await getDoc(statsRef);

    if (statsSnap.exists()) {
      return statsSnap.data();
    } else {
      await setDoc(statsRef, {
        userId: userId,
        gamemode: formattedGameMode,
        points: 0,
        highscoreStreak: 0,
      });

      return {
        points: 0,
        highscoreStreak: 0,
      };
    }
  } catch (error) {
    console.error('Error fetching gameMode stats:', error);
    return {
      points: 0,
      highscoreStreak: 0,
    };
  }
};


// FRIENDS
export const sendFriendRequest = async (currentUserId: string, targetUserId: any, currentUsername:string, showAlert: (message: string, status: 'red' | 'green' | 'blue') => void) => {
  if (!currentUserId || !currentUsername) {
    throw new Error('Current user information is missing.');
  }

  const friendshipId = generateFriendshipId(currentUserId, targetUserId);
  const friendshipRef = doc(FRIENDSHIPS_DATABASE, friendshipId);
  const notificationRef = doc(collection(USERS_DATABASE, targetUserId, 'notifications'));

  // Check if the friendship already exists
  const friendshipDoc = await getDoc(friendshipRef);

  if (friendshipDoc.exists()) {
    const friendshipData = friendshipDoc.data();
    const status = friendshipData?.status;

    if (status === 'accepted') {
      showAlert('You are already friends.', "blue");
      return false;
    } else if (status === 'pending') {
      showAlert('A friend request is already pending.', "blue");
      return false;
    } else if (status === 'blocked') {
      showAlert('You cannot send a friend request to this user.', "blue");
      return false;
    }
  }

  const batch = writeBatch(db);

  // Create friendship document
  batch.set(friendshipRef, {
    userId1: currentUserId,
    userId2: targetUserId,
    userIds: [currentUserId, targetUserId],
    status: 'pending',
    timestamp: serverTimestamp(),
  });

  // Create notification document
  batch.set(notificationRef, {
    id: notificationRef.id,
    friendshipId: friendshipRef.id,
    fromUserId: currentUserId,
    fromUsername: currentUsername,
    type: 'friend_request',
    status: 'unread',
    timestamp: serverTimestamp(),
  });

  try {
    await batch.commit();
    showAlert('Friend request sent!', "green");
  } catch (error) {
    console.error('Error sending friend request:', error);
    throw error;
  }
};

export const fetchUserIdByUsername = async (username: string): Promise<string | null> => {
  const usersCollectionRef = USERS_DATABASE;

  const q = query(usersCollectionRef, where('username', '==', username));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null;
  } else {
    const userId = querySnapshot.docs[0].id;
    return userId
  }
}

export const respondToFriendRequest = async (
  currentUserId: string,
  notificationId: string,
  friendshipId: string,
  action: 'accept' | 'ignore'
) => {
  const friendshipRef = doc(FRIENDSHIPS_DATABASE, friendshipId);
  const notificationRef = doc(USERS_DATABASE, currentUserId, 'notifications', notificationId);

  const batch = writeBatch(db);

  batch.update(friendshipRef, {
    status: action === 'accept' ? 'accepted' : 'ignored',
    [`${action}edAt`]: serverTimestamp(),
  });

  batch.delete(notificationRef);

  await batch.commit();
};

export const getFriends = async (currentUserId: string, gameMode: GameModeType) => {
  const formattedGameMode = modeKeyMap[gameMode]

  const friendIds = await getFriendIds(currentUserId);
  const friendsStats = await getFriendsGamemodeStats(friendIds, formattedGameMode);

  friendsStats.sort((a, b) => (b.currentPoints || 0) - (a.currentPoints || 0));

  return friendsStats;
};

const getFriendIds = async (currentUserId: string): Promise<string[]> => {
  const friendshipsRef = FRIENDSHIPS_DATABASE;
  const q = query(
    friendshipsRef,
    where('status', '==', 'accepted'),
    where('userIds', 'array-contains', currentUserId)
  );

  const snapshot = await getDocs(q);

  const friendIds = snapshot.docs.map(doc => {
    const data = doc.data();
    return data.userId1 === currentUserId ? data.userId2 : data.userId1;
  });

  return friendIds;
};

const getFriendsGamemodeStats = async ( friendIds: string[], gameMode: string ): Promise<FriendType[]> => {
  const friendsStats: FriendType[] = [];

  const batchSize = 10;

  for (let i = 0; i < friendIds.length; i += batchSize) {
    const batch = friendIds.slice(i, i + batchSize);

    const gamemodeStatsCollectionGroup = collectionGroup(db, 'gamemodeStats');

    const q = query(
      gamemodeStatsCollectionGroup,
      where('gamemode', '==', gameMode),
      where('userId', 'in', batch)
    );

    try {
      const statsSnapshot = await getDocs(q);

      // Collect userIds and their stats
      const tempStats: { [userId: string]: Partial<FriendType> } = {};

      statsSnapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        const userId = data.userId;

        tempStats[userId] = {
          id: userId,
          currentPoints: data.points || 0,
          highscoreStreak: data.highscoreStreak || 0,
        };
      });

      // Handle friends who don't have stats for the gameMode
      const foundIds = Object.keys(tempStats);
      const missingIds = batch.filter((id) => !foundIds.includes(id));

      // Collect all user IDs (both with and without stats)
      const allUserIds = [...foundIds, ...missingIds];

      // Batch fetch user documents for all user IDs
      const userDocRefs = allUserIds.map((userId) => doc(USERS_DATABASE, userId));
      const userDocPromises = userDocRefs.map((userDocRef) => getDoc(userDocRef));
      const userDocSnapshots = await Promise.all(userDocPromises);

      // Map user IDs to usernames
      const userIdToUsername: { [userId: string]: string } = {};
      userDocSnapshots.forEach((userDocSnapshot) => {
        const userData = userDocSnapshot.data();
        const userId = userDocSnapshot.id;
        const username = userData?.username || 'Unknown';
        userIdToUsername[userId] = username;
      });

      // Build friendsStats array
      // Friends with stats
      foundIds.forEach((userId) => {
        friendsStats.push({
          id: userId,
          username: userIdToUsername[userId],
          currentPoints: tempStats[userId].currentPoints || 0,
          highscoreStreak: tempStats[userId].highscoreStreak || 0,
        } as FriendType);
      });

      // Friends without stats
      missingIds.forEach((userId) => {
        friendsStats.push({
          id: userId,
          username: userIdToUsername[userId],
          currentPoints: 0,
          highscoreStreak: 0,
        } as FriendType);
      });

    } catch (error) {
      console.error('Error fetching gameMode stats for friends:', error);
    }
  }

  return friendsStats;
};


// NOTIFICATIONS
export const listenToNotificationsUser = (currentUserId: string, callback: (notifications: Notification[]) => void) => {
  const notificationsRef = collection(USERS_DATABASE, currentUserId, 'notifications');
  const q = query(notificationsRef, orderBy('timestamp', 'desc'));

  return onSnapshot(q, (snapshot) => {
    const notifications = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      } as Notification;
    });

    callback(notifications);
  });
};

export const listenToNotificationsGlobal = (callback: (notifications: Notification[]) => void) => {
  const notificationsRef = NOTIFS_DATABASE;
  const q = query(notificationsRef, orderBy('timestamp', 'desc'));

  return onSnapshot(q, (snapshot) => {
    const notifications = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      } as Notification;
    });

    callback(notifications);
  });
};

export const hasUserReadGlobalNotification = async (userId: string | undefined, notificationId: string) => {
  const readNotificationRef = doc(USERS_DATABASE, userId, 'readGlobalNotifications', notificationId);
  const docSnap = await getDoc(readNotificationRef);
  
  return docSnap.exists();
};

export const markGlobalNotificationAsRead = async (userId: string, notificationId: string) => {
  try {
    const readNotificationRef = doc(USERS_DATABASE, userId, 'readGlobalNotifications', notificationId);
    
    const docSnapshot = await getDoc(readNotificationRef);

    if (!docSnapshot.exists()) {
      await setDoc(readNotificationRef, {
        readAt: new Date(),
        notificationId,
      });
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error marking global notification as read: ', error);
  }
};

export const changeAllNotificationsToRead = async (userId: string) => {
  try {
    const notificationsRef = collection(USERS_DATABASE, userId, 'notifications');

    const snapshot = await getDocs(notificationsRef);
    const docs = snapshot.docs;

    const batchSize = 50; 

    for (let i = 0; i < docs.length; i += batchSize) {
      const batch = writeBatch(db);
      const batchDocs = docs.slice(i, i + batchSize);

      batchDocs.forEach((docSnapshot) => {
        batch.update(docSnapshot.ref, { status: 'read' });
      });

      await batch.commit();
    }
  } catch (error) {
    console.error('Error updating notifications to read:', error);
    throw error;
  }
};


/* HELPER FUNCTIONS */

function generateFriendshipId(userId1: string, userId2: string): string {
  return [userId1, userId2].sort().join('_');
}